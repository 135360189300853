<template>
  <b-card>
    <FormBuilder
      label="Multiple Section"
      update
      :data="$store.state.multipleSection.detailData"
      :fields="fields"
      :loading-detail="$store.state.multipleSection.loadingDetail"
      :loading-submit="$store.state.multipleSection.loadingSubmit"
      :diff-only="false"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'title',
          label: 'Title',
          required: true,
        },
        {
          key: 'subtitle',
          label: 'Subtitle',
          type: 'textarea',
          minLength: 5,
          required: true,
        },
        {
          key: 'sort',
          label: 'Sort',
          type: 'number',
          prefix: '#',
          required: true,
        },
        {
          key: 'backgroundImage',
          label: 'Image',
          type: 'image',
          required: true,
        },
        {
          key: 'rule.listType',
          label: 'Section Type',
          type: 'radio',
          required: true,
          options: [
            { text: 'Profile', value: 'profile' },
            { text: 'Listing', value: 'service' },
          ],
        },
        //
        {
          key: 'rule.userIDs',
          label: 'Profiles',
          option_key: 'id',
          type: 'multiple',
          text: 'text',
          actionSearch: 'user/search',
          // initialOptions: true,
          visibility: {
            callback: data => data.find(item => item.key === 'rule.listType').value === 'profile',
          },
        },
        {
          key: 'rule.serviceIDs',
          option_key: 'id',
          label: 'Listings',
          type: 'multiple',
          actionSearch: 'service/search',
          text: 'text',
          visibility: {
            callback: data => data.find(item => item.key === 'rule.listType').value === 'listing',
          },
        },
        {
          key: 'rule.countries',
          option_key: 'code',
          label: 'Countries',
          type: 'multiple',
          actionSearch: 'country/searchCodes',
          text: 'text',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('multipleSection/getDetailEdit', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('multipleSection/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('multipleSection/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>
